import Button from '@/components/button/Button';
import { useEffect, useRef, useState } from 'react';
import { throttle } from '@/src/modules/Common';

export default function MultilineClamp({ description, buttonTitle, classNameBase = 'details' }) {
    const [collapsed, setCollapsed] = useState(true);
    const [buttonVisible, setButtonVisible] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        setCollapsed(true);

        const onResize = throttle(() => {
            const elem = textRef.current;
            if (!elem) return;
            const lineHeight = window.getComputedStyle(elem, null).getPropertyValue('line-height');
            const maxLines = window.getComputedStyle(elem, null).getPropertyValue('-webkit-line-clamp');
            setButtonVisible(elem.scrollHeight > Math.round(parseFloat(lineHeight) * parseFloat(maxLines)));
        });

        onResize();
        window.addEventListener('resize', onResize, false);
        return () => {
            window.removeEventListener('resize', onResize, false);
        };
    }, [description]);

    const classnameSuffix = collapsed ? ` ${classNameBase}__description--clamped` : '';

    return (
        <div className={`${classNameBase}__text`}>
            <p ref={textRef} className={`${classNameBase}__description${classnameSuffix}`}>
                {description}
            </p>
            <Button
                title={
                    <>
                        <span className="button__text button__text--show">{buttonTitle}</span>
                        <span className="button__text button__text--hide">Скрыть</span>
                    </>
                }
                icon={'arrow-down'}
                className={`${classNameBase}__description-button button--icon-right button--large button--clear${
                    buttonVisible ? '' : ' h-hidden'
                }`}
                onClick={() => setCollapsed(!collapsed)}
            />
        </div>
    );
}
