import useCatchup from '@/src/hooks/useCatchup';
import Image from 'next/image';
import backgroundImage from './img/catchup_background.jpg';
import ModalConfirmPurchase from '@/components/modal/purchases/ModalConfirmPurchase';
import usePurchases from '@/src/hooks/usePurchases';
import usePurchasePinCheck from '@/src/hooks/usePurchasePinCheck';
import ModalForm from '@/components/modal/ModalForm';
import { ButtonPrimary } from '@/components/button/Button';
import { useState } from 'react';
import { STOCK_NOTICE } from '@/src/api/v3/models/Stock';

export default function ModalCatchupAdvertising() {
    const catchupStatus = useCatchup();
    const { catchupActivate } = usePurchases();

    const [submitting, setSubmitting] = useState(false);

    async function onConfirm(paymentType) {
        setSubmitting(true);
        try {
            await catchupActivate({
                price: catchupStatus.stock ? catchupStatus.stock?.priceWithDiscount : catchupStatus.currentPrice,
                isTNB: catchupStatus.stock?.isTNB(),
                paymentType,
            });
        } catch (e) {
            setSubmitting(false);
        }
    }

    const stock = catchupStatus?.stock;
    const isTNB = stock?.isTNB();

    // todo styles => css
    return (
        <ModalTNBWrapper
            stock={stock}
            pageTitle={'Подключение услуги "Управляй просмотром"'}
            onConfirm={onConfirm}
            submitting={submitting}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <Image className="modal__img" src={backgroundImage} alt="Управляй просмотром" />
                {isTNB && (
                    <div className="modal__try-buy">
                        <div className="modal__try-buy-label">{stock?.getTitle()}</div>
                        <div className="modal__try-buy-sub-label">Сообщим за&nbsp;3&nbsp;дня до&nbsp;окончания</div>
                        <img className="modal__try-buy-img" src="/assets/img/modal-try-buy.svg" alt="" />
                    </div>
                )}
            </div>
            <div className="modal__content-title modal__content-title--center" style={{ fontWeight: 'bold' }}>
                Управляй просмотром
                {isTNB && (
                    <>
                        <br />
                        {stock?.getTitle(true)}
                    </>
                )}
            </div>
            <div className="modal__paragraph">
                {catchupStatus && getSubtitle(catchupStatus)}
                <br /> Услуга доступна на всех ТВ-каналах, кроме местных каналов (с 21 по 32 кнопку), канала MyZen 4K и
                первых 20 ТВ-каналов (каналы первого и второго мультиплекса).
            </div>
        </ModalTNBWrapper>
    );
}

/**
 * @param {Stock} stock
 * @param {string} pageTitle
 * @param {Function} onConfirm
 * @param {Boolean} submitting
 * @param children
 */
function ModalTNBWrapper({ stock, pageTitle, onConfirm, submitting, children }) {
    const purchasePinCheck = usePurchasePinCheck();
    return stock?.priceWithDiscount === 0 ? (
        <ModalForm
            pageTitle={'Подключить и смотреть'}
            className={'modal--primary modal--subscription'}
            pageUrl={'#catchup'}
        >
            {children}
            <div className="modal__btnset">
                <div className="modal__wrapper-btn">
                    <ButtonPrimary
                        title={`Попробуйте ${stock?.getPeriodText()}`}
                        className="button--large"
                        onClick={() => {
                            purchasePinCheck(onConfirm);
                        }}
                        submitting={submitting}
                    />
                </div>
            </div>
        </ModalForm>
    ) : (
        <ModalConfirmPurchase pageTitle={pageTitle} onConfirm={onConfirm}>
            {children}
        </ModalConfirmPurchase>
    );
}

const getSubtitle = (catchupStatus) => {
    return catchupStatus.stock ? (
        <>
            {catchupStatus.stock.getTitle()}, далее {catchupStatus.currentPrice} ₽ в месяц. {STOCK_NOTICE}
        </>
    ) : (
        <>Стоимость услуги {catchupStatus.currentPrice} ₽ в месяц.</>
    );
};
