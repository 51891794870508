import styles from './Backbg.module.scss';

export default function Backbg({ src, alt, className, action }) {
    // https://caniuse.com/?search=fetchpriority
    // noinspection HtmlUnknownAttribute
    return (
        <div className={styles['backbg'] + (className ? ' ' + className : '')}>
            <div className="backbg__wrap">
                <div className="backbg__img-wrap">
                    {!!src && <img className="backbg__img" src={src} alt={alt} fetchpriority={'high'} /* NOSONAR */ />}
                </div>
                {action && (
                    <div className="container">
                        <div className="backbg__action">{action}</div>
                    </div>
                )}
            </div>
        </div>
    );
}
