import { useEffect, useState } from 'react';
import { useApiStore, useAuthStore } from '@/src/stores/RootStoreProvider';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

/**
 * Возвращает статус услуги "Управляй просмотром"
 * @returns {?CatchupStatus}
 */
export default function useCatchup() {
    const { api } = useApiStore();
    const [value, setValue] = useState(null);
    const { isGuest } = useAuthStore();

    useEffect(() => {
        if (!isGuest) {
            // noinspection JSIgnoredPromiseFromCall
            mutex.runExclusive(async function () {
                const catchupStatus = await api.catchup.getCatchupStatus();
                setValue(catchupStatus);
            });
        }
    }, [api, isGuest]);

    return value;
}
